import { useOfferStore } from '@/src/common/zustand';
import { useTranslation } from 'next-i18next';
import { useContext } from 'react';
import { PaymentOptionContext } from './PaymentOption';
import useCurrencyCodeStore from '@/src/common/zustand/useCurrencyCodeStore';
import { currencyText } from '@/src/common/components/OrderSummary/utils';

const Price = (): JSX.Element => {
  const { t } = useTranslation('prices');
  const currency = useOfferStore((state) => state.offer?.currency.symbol);
  const { currencyCode } = useCurrencyCodeStore();

  const { option } = useContext(PaymentOptionContext);

  const { price, rebills, frequency } = option!;

  const showFrequency = !rebills || rebills === 0;

  const periodAbr = !!frequency ? t(`pricePeriod.${frequency}`) : '';

  return (
    <div className="leading-4">
      <span className="text-lg font-bold leading-6">
        {currencyText(currencyCode, currency, price)}
      </span>
      {showFrequency && (
        <span className="text-sm leading-4 whitespace-nowrap">{periodAbr}</span>
      )}
    </div>
  );
};

export default Price;
