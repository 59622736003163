import { useMemo } from 'react';
import { usePageStore } from '../zustand';
import { ActiveTemplateEnum } from '@/src/components/Layout/Layout.enums';
import { components } from '@/src/schemas/offer';

const useIsDisplayComponent = (
  displayOn: components['schemas']['BlockVisibility'] | null
) => {
  const activeTemplate = usePageStore((state) => state.activeTemplate);

  return useMemo(() => {
    if (
      activeTemplate &&
      displayOn &&
      activeTemplate === ActiveTemplateEnum.MOBILE &&
      !displayOn?.mobile
    ) {
      return false;
    }

    if (
      activeTemplate &&
      displayOn &&
      [
        ActiveTemplateEnum.DESKTOP_TWO_COLUMN,
        ActiveTemplateEnum.DESKTOP_TWO_STEP,
      ].includes(activeTemplate as ActiveTemplateEnum) &&
      !displayOn?.desktop
    ) {
      return false;
    }

    return true;
  }, [activeTemplate, displayOn]);
};

export default useIsDisplayComponent;
