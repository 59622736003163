import { createRGBA, pSBC } from '@/components/helpers/utils';
import { Offer } from '@/src/pages/[host]/[uid]/[slug]';
import { OfferStyle } from './Offer.types';

import { isTaxable } from '@/src/services/TaxService/helpers';
import { DisplayOnItem } from './Offer.enums';

export const getOfferArgs = (offer: Offer) => {
  const { account, ...offerProps } = offer;

  const getAccentColorWithOpacity = (opacity: number) =>
    createRGBA({
      ...offerProps?.customization?.background_color,
      a: opacity,
    });

  const accentColor = createRGBA(offerProps?.customization?.background_color);

  const buttonBackgroundColor = createRGBA(
    offerProps?.customization?.buy_button?.color
  );
  const buttonTextColor = createRGBA(
    offerProps?.customization?.buy_button?.text_color
  );

  const headingFont = offerProps?.customization?.custom_fields?.fonts?.headline;

  const upsell = offerProps?.upsells?.length ? offerProps?.upsells[0] : null;

  let style: OfferStyle = {
    '--accent-color': accentColor,
    '--accent-color-5': getAccentColorWithOpacity(0.05),
    '--accent-color-10': getAccentColorWithOpacity(0.1),
    '--accent-color-15': getAccentColorWithOpacity(0.15),
    '--accent-color-20': getAccentColorWithOpacity(0.2),
    '--accent-color-50': getAccentColorWithOpacity(0.5),
    '--accent-color-darker': pSBC(-0.6, accentColor)!,
    '--button-background-color': buttonBackgroundColor,
    '--button-text-color': buttonTextColor,
    '--button-text-color-lighter': pSBC(0.6, buttonTextColor)!,
    '--heading-font': headingFont,
  };

  if (upsell && upsell.customization) {
    style = {
      ...style,
      '--upsell-accent-color': createRGBA(upsell.customization.accent_color),
      '--upsell-accent-bg-color': createRGBA({
        ...upsell.customization.accent_color,
        a: 0.1,
      }),
      '--upsell-btn-bg-color': createRGBA(upsell.customization.btn_bg_color),
      '--upsell-btn-text-color': createRGBA(
        upsell.customization.btn_text_color
      ),
    };
  }

  const desktopPageTemplate = offerProps?.checkout_template_type?.id;

  const SidebarArgs = {
    items: offerProps.customization?.custom_fields?.items,
    testimonials: offerProps.customization?.testimonials,
  };

  const LogoArgs = {
    imageUrl: offerProps.customization?.header?.logo ?? null,
    logoText: null,
    sealType: offerProps.customization?.guarantee_seal?.type ?? null,
  };

  const UpsellArgs =
    upsell && upsell.customization
      ? {
          title: upsell.customization.title,
          description: upsell.customization.description,
          logoUrl: offerProps.customization?.header?.logo ?? null,
          isVideoUsed: upsell.customization.video_used,
          yesButtonText: upsell.customization.yes_button_text,
          noButtonText: upsell.customization.no_button_text,
          videoUrl: upsell.customization.video_url,
          imgUrl: upsell.customization.img_url,
          confirmationEnabled: upsell.customization.confirmation_enabled,
          paymentOption: upsell.payment_option,
          product: {
            id: upsell.product.id,
            name: upsell.product.name,
            ...(!!upsell.product.tax_behavior && {
              tax_behavior: upsell.product.tax_behavior,
            }),
          },
        }
      : {};

  const IntroArgs = {
    headline: offerProps.customization?.headline,
  };

  const PaymentModeKey = offerProps.status === 'live' ? 'live' : 'test';
  const StripeTaxKey =
    offerProps.status === 'live'
      ? 'stripe_tax_livemode'
      : 'stripe_tax_testmode';

  const OrderSummaryArgs = {
    customizeProduct: offerProps.customization.product,
    paymentPage: offerProps,
    nameTemplate: 'two-column',
    discount: null,
    accountSlug: account.slug,
    stripeTaxEnabled:
      account?.integrations.find((i) => i.system_name === 'Stripe')?.options?.[
        StripeTaxKey
      ] ?? false,
  };

  const ContactInformationArgs = {
    fields: offer.customization?.requested_fields,
  };

  // Original definition in @/src/helpers/isMainTaxable
  const mainTaxable = isTaxable(offer?.product.product_tax_code);

  // Original definition in @/src/helpers/isBumpEnabled
  const bumpEnabled = !!offer?.bumps[0]?.enabled;

  // Original definition in @/src/helpers/isBumpTaxable
  const bumpTaxable =
    bumpEnabled && isTaxable(offer?.bumps[0]?.product?.product_tax_code);

  // Original definition in @/src/helpers/isUpsellEnabled
  const upsellEnabled = !!offer?.upsells[0]?.settings?.enabled;

  // Original definition in @/src/helpers/isUpsellTaxable
  const upsellTaxable =
    upsellEnabled && isTaxable(offer?.upsells[0]?.product?.product_tax_code);

  // Display the billing address form if any of the following conditions are met:
  // - A billing address form is enabled (offer.customization.requested_fields.customers_address != "no"
  // - Tax collection is enabled, and at least one product is taxable
  // - The tax ID field is enabled
  // Note: offer.address_required is a high-level setting that is currently not used in the appearance editor
  let addressEnabled: boolean =
    offer.customization.requested_fields.customers_address != 'no' ||
    offer.customization.requested_fields.tax_id ||
    (OrderSummaryArgs.stripeTaxEnabled &&
      (mainTaxable || bumpTaxable || upsellTaxable));

  // Hide address form for specific E2E test pages on staging/prod - https://app.clickup.com/t/863gdhmbg
  if (
    (account.slug === 'vomae2estaging' && offer?.id === 359) ||
    (account.slug === 'vomademo' && offer?.id === 644)
  ) {
    addressEnabled = false;
  }

  const MobileOrderSummaryArgs = { ...OrderSummaryArgs, mobileDrawer: true };

  const OrderBumpArgs = {
    bump: offer.bumps[0],
    currencySymbol: offer.currency?.symbol,
    buttonBackgroundColor: offer.customization?.buy_button?.color,
  };

  const TermsAndConditionsArgs = {
    termsEnabled:
      offerProps.customization?.terms_and_conditions?.enabled || false,
    termsText: offerProps.customization?.terms_and_conditions?.text ?? null,
    gdprEnabled: offer.customization?.gdpr?.visibility || false,
    gdprText: offerProps.customization?.gdpr?.text ?? null,
  };

  const termsAndConditionsEnabled =
    TermsAndConditionsArgs.termsEnabled || TermsAndConditionsArgs.gdprEnabled;

  const PaymentButtonArgs = {
    paymentPage: offerProps,
    integrations: account.integrations,
    paymentIntegrationId: offerProps.payment_integrations.find(
      (i) => i.system_name === 'Stripe'
    )?.id,
    buy: true,
    textColor: { r: 66, g: 135, b: 245, a: 1 },
    termsEnabled: TermsAndConditionsArgs.termsEnabled,
    gdprEnabled: TermsAndConditionsArgs.gdprEnabled,
  };

  const StripeArgs = {
    stripePublicKey: account.integrations.find(
      (i) => i.system_name === 'Stripe'
    )?.credentials[PaymentModeKey]?.public_key,
    paymentPageId: offerProps.id,
    paymentIntegrationId:
      offerProps.payment_integrations.find((i) => i.system_name === 'Stripe')
        ?.id ?? null,
    addressEnabled,
    currency: offerProps.currency?.code ?? 'USD',
    paymentPage: offerProps,
    paymentMethods: offerProps?.payment_methods ?? null,
  };

  const SubscriptionNoticesArgs = {
    paymentPage: offerProps,
    buy: true,
  };

  const payPalIntegration = offerProps.payment_integrations.find(
    (i) => i.system_name === 'Paypal'
  );

  const payPalEnabled = Boolean(payPalIntegration?.id ?? false);

  const PayPalArgs = {
    clientId:
      account.integrations.find((i) => i.system_name === 'Paypal')
        ?.credentials?.[PaymentModeKey].public_key ?? false,
    termsEnabled: TermsAndConditionsArgs.termsEnabled,
    gdprEnabled: TermsAndConditionsArgs.gdprEnabled,
    thankYouPageUrl: offerProps.thank_you_page_url ?? false,
    paymentPageId: offerProps.id,
    paymentIntegrationId: payPalIntegration?.id ?? null,
    paymentPage: offerProps,
    integrations: account.integrations,
  };

  const FooterArgs = {
    footerLinks: offerProps.customization?.footer_links,
    footerDisplayOn:
      offerProps.customization?.footer_display_on ?? DisplayOnItem,
  };

  return {
    logo: LogoArgs,
    upsell: UpsellArgs,
    intro: IntroArgs,
    orderSummary: OrderSummaryArgs,
    mobileOrderSummary: MobileOrderSummaryArgs,
    contactInformation: ContactInformationArgs,
    addressEnabled,
    orderBump: OrderBumpArgs,
    stripe: StripeArgs,
    termsAndConditions: TermsAndConditionsArgs,
    subscriptionNotices: SubscriptionNoticesArgs,
    payPal: PayPalArgs,
    paymentButton: PaymentButtonArgs,
    footer: FooterArgs,
    sidebar: SidebarArgs,
    desktopPageTemplate: desktopPageTemplate,
    style,
    deadline: offerProps.deadline,
    bonuses: offerProps.bonuses,
    // Bool
    bumpEnabled,
    termsAndConditionsEnabled,
    payPalEnabled,
  };
};
