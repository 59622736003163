import dynamic from 'next/dynamic';
import { components } from '@/src/schemas/offer';
import { DisplayOnItem } from '@/src/components/Offer/Offer.enums';

const Testimonials = dynamic(() => import('@/components/Testimonials'));

const Text = dynamic(() => import('@/components/Text'));

type SidebarItemsProps = {
  items: components['schemas']['CheckoutSchema']['custom_fields']['items'];
  testimonials?: components['schemas']['CheckoutSchema']['testimonials'];
};

const SidebarItems = ({ items, testimonials }: SidebarItemsProps) => {
  const sidebar = items?.map((item, index) => {
    switch (item.type) {
      case 'text':
        return (
          <Text
            key={index}
            heading={item.title!}
            text={item.text!}
            displayOn={item.display_on || DisplayOnItem}
          />
        );
      case 'testimonial':
        return (
          <Testimonials
            key={index}
            heading={item.title!}
            testimonials={testimonials!}
            displayOn={item.display_on || DisplayOnItem}
          />
        );
    }
  });
  return (
    <>
      {sidebar?.map((item, index) => (
        <div className="last:mb-8" key={index}>
          {item}
        </div>
      ))}
    </>
  );
};

const Sidebar = (props) => {
  return (
    <div className="mt-8 pr-6" data-testid="sidebar">
      <SidebarItems {...props} />
    </div>
  );
};

export default Sidebar;
